<template>
  <PerusahaanForm mode="Ubah" module="Perusahaan"> </PerusahaanForm>
</template>

<script>
import PerusahaanForm from './form';

const PerusahaanUpdate = {
  name: 'PerusahaanUpdate',
  components: { PerusahaanForm },
};

export default PerusahaanUpdate;
</script>
